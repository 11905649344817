import React, { useState, useEffect } from 'react';
import './contact.css';
import { useNavigate } from 'react-router-dom';

function Contact() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const [topbarShow, setTopbarShow] = useState(0); // State to control menu visibility
  const navigate = useNavigate();

  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTopbarShow(1)
      setTimeout(() => {
      // Schedule timers for incrementally showing menu items
      for (let i = 1; i <= 5; i++) {
        const timer = setTimeout(() => {
          setShowMenu(i);
        }, i * 100); // Delay each item by 500ms
        timers.push(timer);
      }
    }, 100); 
    } else {
      setShowMenu(0);
      setTimeout(() => {
        setTopbarShow(0);
      }, 200);
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app-container">
      <div className="top-bar">
        <button className={`arrow-left ${topbarShow ? (menuOpen >= 1 ? 'hide' : 'show') : ''}`} onClick={() => handleMenuClick('')}>
          Home
        </button>
        {showMenu > 0 && (
          <button className="topbar-menu-title" onClick={toggleMenu}>
            Back
          </button>
        )}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="column">
              <div className="center-column">
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('')}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('experience')}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('projects')}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={toggleMenu}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`contact-container ${menuOpen ? 'open' : ''}`}>
          <div className="contact-text-container">
            <p className="contact-text">For any inquiries or opportunities, please contact me using the links below. To see what I’m up to, visit my Substack and GitHub.</p>
            <p className="contact-text">My current interests include game theory, prediction markets, fintech, entrepreneurship, politics, and philosophy. However, I am interested in just about anything!</p>
            <p className="contact-text">I am always looking to speak with new and interesting people, so don’t be shy—reach out and arrange a time to chat.</p>
          </div>
          <div className={`contact-icon-container ${menuOpen ? 'open' : ''}`}>
              <a href="mailto:anthonyduncalf_AT_gmail.com">
                <img src="/Assets/email.png" alt="Email Icon" className="contact-icon"/>
              </a>
              <a href="https://github.com/SovietMeteor10" target="_blank" rel="noopener noreferrer">
                <img src="/Assets/github.png" alt="GitHub Icon" className="contact-icon"/>
              </a>
              <a href="https://www.linkedin.com/in/aduncalf/" target="_blank" rel="noopener noreferrer">
                <img src="/Assets/linkedin.png" alt="LinkedIn Icon" className="contact-icon"/>
              </a>
              <a href="https://substack.com/@anthonyduncalf?" target="_blank" rel="noopener noreferrer">
                <img src="/Assets/substack_logo.png" alt="Substack Icon" className="substack-icon"/>
              </a>
            </div>
      </div>
    </div>
  );
}

export default Contact;
