import React, { useState, useEffect } from 'react';
import './projects.css';
import { useNavigate } from 'react-router-dom';

function Projects() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const [topbarShow, setTopbarShow] = useState(0); // State to control menu visibility
  const navigate = useNavigate();

  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTopbarShow(1)
      setTimeout(() => {
      // Schedule timers for incrementally showing menu items
      for (let i = 1; i <= 5; i++) {
        const timer = setTimeout(() => {
          setShowMenu(i);
        }, i * 100); // Delay each item by 500ms
        timers.push(timer);
      }
    }, 100); 
    } else {
      setShowMenu(0);
      setTimeout(() => {
        setTopbarShow(0);
      }, 200);
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app-container">
      <div className={`top-bar ${!menuOpen ? 'hide' : ''}`}>
        <button className={`arrow-left ${topbarShow ? (menuOpen >= 1 ? 'hide' : 'show') : ''}`} onClick={() => handleMenuClick('')}>
          Home
        </button>
        {showMenu > 0 && (
          <button className={`topbar-menu-title ${!menuOpen ? 'hide' : ''}`} onClick={toggleMenu}>
            Back
          </button>
        )}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className={`column ${!menuOpen ? 'hide' : ''}`}>
              <div className={`center-column ${!menuOpen ? 'hide' : ''}`}>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('')}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('experience')}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={toggleMenu}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('contact')}
                >
                  Contact
                </button>
              </div>
            </div> 
          </div>
        )}
      </div> 
      <div className={`project-column ${menuOpen ? 'open' : ''}`}>
        <div className="project-center-column">
          <button
            className={`project-menu-buttons ${menuOpen ? 'open' : ''}`}
            onClick={() => handleMenuClick('stat-arb')}
          >
            StatArb Project
          </button>
          <button
            className={`project-menu-buttons ${menuOpen ? 'open' : ''}`}
            onClick={() => handleMenuClick('ml-projects')}
          >
            ML Projects
          </button>
          <button
            className={`project-menu-buttons ${menuOpen ? 'open' : ''}`}
            onClick={() => handleMenuClick('dualnback')}
          >
            Dual N Back
          </button>
          <button
            className={`project-menu-buttons ${menuOpen ? 'open' : ''}`}
            onClick={() => handleMenuClick('mirage')}
          >
            Mirage
          </button>
          {/*<button
            className={`project-menu-buttons-last ${menuOpen ? 'open' : ''}`}
            onClick={() => handleMenuClick('')}
          >
            Statistical Arb Strategy
        </button>*/}
        </div>
      </div>
    </div>
  );
}

export default Projects;
