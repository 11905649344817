import React, { useState, useEffect } from 'react';
import './home.css';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const navigate = useNavigate();

  const handleButtonClick = () => {
    window.location.href = "https://en.wikipedia.org/wiki/Special:Random"; // Set the URL you want to navigate to
  };

  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTimeout(() => {
      
      // Schedule timers for incrementally showing menu items
      for (let i = 1; i <= 5; i++) {
        const timer = setTimeout(() => {
          setShowMenu(i);
        }, i * 100); 
        timers.push(timer);
      }
    }, 100); 
    } else {
      setShowMenu(0); // Reset menu visibility immediately
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app-container">
      <div className="top-bar">
        {showMenu > 0 && (
          <button className="topbar-menu-title" onClick={toggleMenu}>
            Back
          </button>
        )}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="column">
              <div className="center-column">
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={toggleMenu}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('experience')}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('projects')}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('contact')}
                >
                  Contact
                </button>
              </div>
            </div> 
          </div>
        )}
      </div>
      <div className={`home-page-container ${menuOpen ? 'open' : ''}`}>
        <div className={`home-text-container ${menuOpen ? 'open' : ''}`}>
          <p className={`home-text ${menuOpen ? 'open' : ''}`}>My name is <em className="name">Anthony Duncalf</em>.</p>
          <p className={`home-text ${menuOpen ? 'open' : ''}`}>As a technologist and entrepreneur I am passionate about collaborating on innovative projects and building businesses. I have leveraged my quantitative background as a Physics graduate through roles in data science, venture capital, and risk management.</p>
          <p className={`home-text ${menuOpen ? 'open' : ''}`}>Alongside my career I have pursued various personal projects including founding Mirage, a tech startup that developed a novel AR mobile application. From September 2024 I will be undertaking an MSc in Computing at Imperial College London with the aim of transitioning to a career in algorithmic trading.</p>
          <p className={`home-text ${menuOpen ? 'open' : ''}`}>If you are driven by creating value, are passionate about technology, and have insatiable curiosity, feel free to connect with me on LinkedIn!</p>
        </div>
        <div className={`home-button-container ${menuOpen ? 'open' : ''}`} >
          <button className={`home-button ${menuOpen ? 'open' : ''}`} onClick={handleButtonClick}>Press Me</button>
        </div>
      </div>
    </div> 
  );
}

export default Home;
