import React, { useState, useEffect } from 'react';
import './statarb.css';
import { useNavigate } from 'react-router-dom';

function StatArb() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const [topbarShow, setTopbarShow] = useState(0); // State to control menu visibility
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [pageWidth, setPageWidth] = useState(window.innerWidth); // Track page width

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTopbarShow(1);
      setTimeout(() => {
        // Schedule timers for incrementally showing menu items
        for (let i = 1; i <= 5; i++) {
          const timer = setTimeout(() => {
            setShowMenu(i);
          }, i * 100); // Delay each item by 100ms
          timers.push(timer);
        }
      }, 100);
    } else {
      setShowMenu(0);
      setTimeout(() => {
        setTopbarShow(0);
      }, 250);
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app-container">
      <div className="top-bar">
        <button
          className={`arrow-left ${topbarShow ? (menuOpen >= 1 ? 'hide' : 'show') : ''}`}
          onClick={() => handleMenuClick('projects')}
        >
          Projects
        </button>
        {showMenu > 0 && (
          <button
            className={`topbar-menu-title ${!menuOpen ? 'hide' : ''}`}
            onClick={toggleMenu}
          >
            Back
          </button>
        )}
        <div
          className={`hamburger ${menuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="column">
              <div className="center-column">
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('')}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('experience')}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('projects')}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('contact')}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {pageWidth < 1300 ? (
        <div className="page-content">
          <div className="center-page">
            <div className="content-title-container">
              <div className={`content-title ${menuOpen ? 'open' : ''}`}>
                Statistical Arbitrage Project
              </div>
              <div className={`content-subtitle-stat ${menuOpen ? 'open' : ''}`}>
              This page is dedicated to my Statistical Arbitrage project where I collaborated with my good friend Sam Scott to develop and operate a statistical arbitrage bot on probability markets such as Polymarket and Manifold.
              </div>
            </div>
            <p>More to come...</p>
            {/*
            <img
              src="/Assets/MNIST.png"
              alt="MNIST Photo"
              className={`content-image ${menuOpen ? 'open' : ''}`}
            />
            
            <div className={`content-text-container ${menuOpen ? 'open' : ''}`}>
              <p>
                Currently I have an ongoing side-project to build out my understanding of machine learning. In October 2023, I sat down and took the time to learn the maths and theory behind machine learning by completing the{' '}
                <a href="https://openlearninglibrary.mit.edu/courses/course-v1:MITx+6.036+1T2019/about">
                  MIT Open Learn Course: Introduction to Machine Learning
                </a>. I pushed through and upon finishing quickly got sidetracked with life events. I then worked on some other coding projects but I am now trying to work on the practical side of ML, namely the programming. So I’ve spent a number of hours pouring over the{' '}
                <a href="https://pytorch.org/docs/stable/index.html">PyTorch documentation</a> and decided to create a model that will recognize handwriting of numbers 0-9 using the{' '}
                <a href="https://en.wikipedia.org/wiki/MNIST_database">MNIST database</a>.
              </p>
              <p>
                I find much more satisfaction in being able to see the fruit of my labour visually and this spurred me to build a website to showcase the model. The website was made with JavaScript and the React framework for front-end interactions, CSS for responsive styling, and uses Flask to run inference with the model for predicting the user inputs. I have since decided that I will continue building small models and showcasing them on this website so watch this space. If you have any suggestions of interesting ML challenges, please reach out!
              </p>
            </div>*/}
          </div>
          {/*<footer className={`footer ${menuOpen ? 'open' : ''}`}>
            <div className="footer-buttons">
              <button className="footer-button" onClick={() => handleMenuClick('')}>Home</button>
              <button className="footer-button" onClick={() => handleMenuClick('experience')}>Experience</button>
              <button className="footer-button" onClick={() => handleMenuClick('projects')}>Projects</button>
              <button className="footer-button" onClick={() => handleMenuClick('contact')}>Contact</button>
            </div>
            <div className="footer-copyright">
              &copy; {currentYear} Anthony Duncalf. All rights reserved.
            </div>
        </footer>*/}
        </div>
      ) : (
        <div className="page-content">
          <div className="content-title-container-large">
            <div className={`content-title-large ${menuOpen ? 'open' : ''}`}>
              Statistical Arbitrage Project
            </div>
            <div className={`content-subtitle-large ${menuOpen ? 'open' : ''}`}>
              This page is dedicated to my Statistical Arbitrage project where I collaborated with my good friend Sam Scott to develop and operate a statistical arbitrage bot on probability markets such as Polymarket and Manifold.
            </div>
          </div>
          <p>More to come...</p>
          {/*<div className="content-row-large">
            <div className="image-container">
              <img
                src="/Assets/MNIST.png"
                alt="MNIST Photo"
                className={`content-image-large ${menuOpen ? 'open' : ''}`}
              />
            </div>
            <div className={`content-text-container-large ${menuOpen ? 'open' : ''}`}>
              <p className="mnist-text-large">
                Currently I have an ongoing side-project to build out my understanding of machine learning. In October 2023, I sat down and took the time to learn the maths and theory behind machine learning by completing the{' '}
                <a href="https://openlearninglibrary.mit.edu/courses/course-v1:MITx+6.036+1T2019/about">
                  MIT Open Learn Course: Introduction to Machine Learning
                </a>. I pushed through and upon finishing quickly got sidetracked with life events. I then worked on some other coding projects but I am now trying to work on the practical side of ML, namely the programming. So I’ve spent a number of hours pouring over the{' '}
                <a href="https://pytorch.org/docs/stable/index.html">PyTorch documentation</a> and decided to create a model that will recognize handwriting of numbers 0-9 using the{' '}
                <a href="https://en.wikipedia.org/wiki/MNIST_database">MNIST database</a>.
              </p>
              <p className="mnist-text-large">
                I find much more satisfaction in being able to see the fruit of my labour visually and this spurred me to build a website to showcase the model. The website was made with JavaScript and the React framework for front-end interactions, CSS for responsive styling, and uses Flask to run inference with the model for predicting the user inputs. I have since decided that I will continue building small models and showcasing them on this website so watch this space. If you have any suggestions of interesting ML challenges, please reach out!
              </p>
            </div>
          </div> 
          <footer className={`footer-large ${menuOpen ? 'open' : ''}`}>
            <div className="footer-buttons-large">
              <button className="footer-button-large" onClick={() => handleMenuClick('')}>Home</button>
              <button className="footer-button-large" onClick={() => handleMenuClick('experience')}>Experience</button>
              <button className="footer-button-large" onClick={() => handleMenuClick('projects')}>Projects</button>
              <button className="footer-button-large" onClick={() => handleMenuClick('contact')}>Contact</button>
            </div>
            <div className="footer-copyright-large">
              &copy; {currentYear} Anthony Duncalf. All rights reserved.
            </div>
          </footer>*/}
        </div>
      )}
    </div>
  );
}

export default StatArb;
