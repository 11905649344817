import React, { useState, useEffect } from 'react';
import './dualnback.css';
import { useNavigate } from 'react-router-dom';

function DualNBack() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const [topbarShow, setTopbarShow] = useState(0); // State to control menu visibility
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [pageWidth, setPageWidth] = useState(window.innerWidth); // Track page width

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTopbarShow(1)
      setTimeout(() => {
      // Schedule timers for incrementally showing menu items
      for (let i = 1; i <= 5; i++) {
        const timer = setTimeout(() => {
          setShowMenu(i);
        }, i * 100); // Delay each item by 500ms
        timers.push(timer);
      }
    }, 100); 
    } else {
      setShowMenu(0);
      setTimeout(() => {
        setTopbarShow(0);
      }, 250);
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app-container">
      <div className="top-bar">
        <button className={`arrow-left ${topbarShow ? (menuOpen >= 1 ? 'hide' : 'show') : ''}`} onClick={() => handleMenuClick('projects')}>
          Projects
        </button>
        {showMenu > 0 && (
          <button className={`topbar-menu-title ${!menuOpen ? 'hide' : ''}`} onClick={toggleMenu}>
            Back
          </button>
        )}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="column">
              <div className="center-column">
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('')}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('experience')}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('projects')}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('contact')}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {pageWidth < 1300 ? (
      <div className="page-content">
        <div className="center-page">
          <div>
          <div className="content-title-container">
            <div className={`content-title ${menuOpen ? 'open' : ''}`}>
              Dual N Back
            </div>
            <div className={`content-subtitle ${menuOpen ? 'open' : ''}`}>
              Dual N Back is a game I built that is designed to improve working memory capacity.
            </div>
          </div>
          </div>
          <img src="/Assets/dualnback2.png" alt="DualNBack Photo" className={`content-image ${menuOpen ? 'open' : ''}`}></img>
          <div className={`dnb-text-container ${menuOpen ? 'open' : ''}`}>
            <p className="dnb-text">
              I developed the Dual N Back game with the aim of bringing advanced cognitive training to people for free and to enhance my proficiency in modern web development practices. Dual N Back is a cognitive training exercise aimed at improving working memory and fluid intelligence by challenging users to recognise sequences of stimuli—position, colour, shape, and sound—based on their previous occurrences. This method has been cited as a means of staving off neurodegenerative conditions such as dementia while offering enhanced cognitive function, making it an attractive daily exercise.
            </p>
            <p className="dnb-text">
              The website is designed to be minimalist and is optimised for usability and accessibility across various devices. It utilises JavaScript and the React framework for front-end interactions, CSS for responsive styling, and backend API integration for real-time performance tracking and data management. This combination highlights the application’s robust architecture and user-centric design, making it an effective tool for cognitive training.
            </p>
            <p className="dnb-text">
              I first heard about Dual N Back in an article by <a href="https://gwern.net/dnb-faq#dual-n-back" >Gwern</a>  and was intrigued by the potential benefits. I struggled to find a free, high-quality version online and was simultaneously looking for a coding challenge. This led me to build the site. Overall, I am pleased with the outcome and use it regularly to train myself. I may return to add more features to this project in the future, so watch this space.
            </p>
            <a href="https://www.dualnback.co.uk" className="dnb-text">Link to site</a>
          </div>
        </div>
      <footer className={`footer ${menuOpen ? 'open' : ''}`}>
        <div className="footer-buttons">
          <button className="footer-button" onClick={() => handleMenuClick('')}>Home</button>
          <button className="footer-button" onClick={() => handleMenuClick('experience')}>Experience</button>
          <button className="footer-button" onClick={() => handleMenuClick('projects')}>Projects</button>
          <button className="footer-button" onClick={() => handleMenuClick('contact')}>Contact</button>
        </div>
        <div className="footer-copyright">
          &copy; {currentYear} Anthony Duncalf. All rights reserved.
        </div>
      </footer>
      </div>
      ) : (
        <div className="page-content">
          <div className="content-title-container-large">
            <div className={`content-title-large ${menuOpen ? 'open' : ''}`}>
              Dual N Back
            </div>
            <div className={`content-subtitle-large ${menuOpen ? 'open' : ''}`}>
              Dual N Back is a game I built that is designed to improve working memory capacity.
            </div> 
          </div>
          <div className="content-row-large">
            <div className="image-container">
              <img src="/Assets/dualnback2.png" alt="DualNBack Photo" className={`content-image-large ${menuOpen ? 'open' : ''}`}></img>
            </div>
            <div className={`dnb-text-container-large ${menuOpen ? 'open' : ''}`}>
              <p className="dnb-text-large">
                I developed the Dual N Back game with the aim of bringing advanced cognitive training to people for free and to enhance my proficiency in modern web development practices. Dual N Back is a cognitive training exercise aimed at improving working memory and fluid intelligence by challenging users to recognise sequences of stimuli—position, colour, shape, and sound—based on their previous occurrences. This method has been cited as a means of staving off neurodegenerative conditions such as dementia while offering enhanced cognitive function, making it an attractive daily exercise.
              </p>
              <p className="dnb-text-large">
                The website is designed to be minimalist and is optimised for usability and accessibility across various devices. It utilises JavaScript and the React framework for front-end interactions, CSS for responsive styling, and backend API integration for real-time performance tracking and data management. This combination highlights the application’s robust architecture and user-centric design, making it an effective tool for cognitive training.
              </p>
              <p className="dnb-text-large">
                I first heard about Dual N Back in an article by <a href="https://gwern.net/dnb-faq#dual-n-back" >Gwern</a>  and was intrigued by the potential benefits. I struggled to find a free, high-quality version online and was simultaneously looking for a coding challenge. This led me to build the site. Overall, I am pleased with the outcome and use it regularly to train myself. I may return to add more features to this project in the future, so watch this space.
              </p>
              <div className="center-link">
                <a href="https://www.dualnback.co.uk" className="dnb-text-large">{"-> Link to site <-"}</a>
              </div>
            </div>
          </div>
          <footer className={`footer-large ${menuOpen ? 'open' : ''}`}>
            <div className="footer-buttons-large">
              <button className="footer-button-large" onClick={() => handleMenuClick('')}>Home</button>
              <button className="footer-button-large" onClick={() => handleMenuClick('experience')}>Experience</button>
              <button className="footer-button-large" onClick={() => handleMenuClick('projects')}>Projects</button>
              <button className="footer-button-large" onClick={() => handleMenuClick('contact')}>Contact</button>
            </div>
            <div className="footer-copyright-large">
              &copy; {currentYear} Anthony Duncalf. All rights reserved.
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}

export default DualNBack;
