import React, { useState, useEffect } from 'react';
import './mirage.css';
import { useNavigate } from 'react-router-dom';

function Mirage() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const [topbarShow, setTopbarShow] = useState(0); // State to control menu visibility
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [pageWidth, setPageWidth] = useState(window.innerWidth); // Track page width

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTopbarShow(1)
      setTimeout(() => {
      // Schedule timers for incrementally showing menu items
      for (let i = 1; i <= 5; i++) {
        const timer = setTimeout(() => {
          setShowMenu(i);
        }, i * 100); // Delay each item by 500ms
        timers.push(timer);
      }
    }, 100); 
    } else {
      setShowMenu(0);
      setTimeout(() => {
        setTopbarShow(0);
      }, 100);
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app-container">
      <div className="top-bar">
        <button className={`arrow-left ${topbarShow ? (menuOpen >= 1 ? 'hide' : 'show') : ''}`} onClick={() => handleMenuClick('projects')}>
          Projects
        </button>
        {showMenu > 0 && (
          <button className={`topbar-menu-title ${!menuOpen ? 'hide' : ''}`} onClick={toggleMenu}>
            Back
          </button>
        )}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="column">
              <div className="center-column">
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('')}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('experience')}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('projects')}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('contact')}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {pageWidth < 1300 ? (
      <div className="page-content">
        <div className="center-page">
          <div className="content-title-container">
            <div className={`content-title ${menuOpen ? 'open' : ''}`}>
              Mirage
            </div>
            <div className={`content-subtitle ${menuOpen ? 'open' : ''}`}>
              Mirage was an AR mobile app startup I founded in 2021.
            </div>
          </div>
          <img src="/Assets/mirage_app.png" alt="Mirage App" className={`content-image ${menuOpen ? 'open' : ''}`}></img>
          <div className={`content-text-container ${menuOpen ? 'open' : ''}`}>
            <p className="content-text">During the final weeks of my undergraduate degree, I conceived the idea for Mirage, a real-time mobile AR application for socialising using digital assets, inspired by the success of Pokémon GO and the emerging metaverse. The project excited me greatly, and I dedicated six months to market research, finding co-founders, and building a team. As the CEO, I led product development, networking, and pitching, resulting in an AR art exhibition in Regent's Park and the rollout of the app to 50 businesses on our waitlist.</p>
          </div>
          <img src="/Assets/mirage_shell.jpeg" alt="Shell AR Item" className={`content-image ${menuOpen ? 'open' : ''}`}></img>
          <div className={`content-text-container ${menuOpen ? 'open' : ''}`}>
            <p className="content-text">Despite the challenges of co-founder fatigue, illness, and financial constraints that led to the project's closure, the experience equipped me with valuable skills in entrepreneurship, leadership, technical development, and business acumen. I look forward to future opportunities in the start-up ecosystem, whether as a founder, consultant, or team member, where the camaraderie of an aligned team continues to inspire me.</p>
          </div>
        </div>
        <footer className={`footer ${menuOpen ? 'open' : ''}`}>
          <div className="footer-buttons">
            <button className="footer-button" onClick={() => handleMenuClick('')}>Home</button>
            <button className="footer-button" onClick={() => handleMenuClick('experience')}>Experience</button>
            <button className="footer-button" onClick={() => handleMenuClick('projects')}>Projects</button>
            <button className="footer-button" onClick={() => handleMenuClick('contact')}>Contact</button>
          </div>
          <div className="footer-copyright">
            &copy; {currentYear} Anthony Duncalf. All rights reserved.
          </div>
        </footer>
      </div> ) : (
        <div className="page-content">
          <div className="content-title-container-large">
            <div className={`content-title-large ${menuOpen ? 'open' : ''}`}>
              Mirage
            </div>
            <div className={`content-subtitle-large ${menuOpen ? 'open' : ''}`}>
              Mirage was an AR mobile app startup I founded in 2021.
            </div>
          </div>
          <div className="content-row-large">
            <div>
              <img src="/Assets/mirage_app.png" alt="Mirage App" className={`content-image-large ${menuOpen ? 'open' : ''}`}></img>
            </div>
            <div className={`content-text-container-large ${menuOpen ? 'open' : ''}`}>
              <p className="content-text-large">During the final weeks of my undergraduate degree, I conceived the idea for Mirage, a real-time mobile AR application for socialising using digital assets, inspired by the success of Pokémon GO and the emerging metaverse. The project excited me greatly, and I dedicated six months to market research, finding co-founders, and building a team. As the CEO, I led product development, networking, and pitching, resulting in an AR art exhibition in Regent's Park and the rollout of the app to 50 businesses on our waitlist.</p>
            </div>
          </div>
          <div className="content-row-large">
          <div className={`content-text-container-large ${menuOpen ? 'open' : ''}`}>
            <p className="content-text-large">Despite the challenges of co-founder fatigue, illness, and financial constraints that led to the project's closure, the experience equipped me with valuable skills in entrepreneurship, leadership, technical development, and business acumen. I look forward to future opportunities in the start-up ecosystem, whether as a founder, consultant, or team member, where the camaraderie of an aligned team continues to inspire me.</p>
          </div>
          <div>
            <img src="/Assets/mirage_shell.jpeg" alt="Shell AR Item" className={`content-image-large ${menuOpen ? 'open' : ''}`}></img>
          </div>
        </div>
        <footer className={`footer-large ${menuOpen ? 'open' : ''}`}>
          <div className="footer-button-large">
            <button className="footer-button-large" onClick={() => handleMenuClick('')}>Home</button>
            <button className="footer-button-large" onClick={() => handleMenuClick('experience')}>Experience</button>
            <button className="footer-button-large" onClick={() => handleMenuClick('projects')}>Projects</button>
            <button className="footer-button-large" onClick={() => handleMenuClick('contact')}>Contact</button>
          </div>
          <div className="footer-copyright">
            &copy; {currentYear} Anthony Duncalf. All rights reserved.
          </div>
        </footer>
      </div>
      )}
    </div>
  );
}

export default Mirage;
