import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Home from './Home.js';  
import Experience from './Experience.js'; 
import Projects from './Projects.js'; 
import Contact from './Contact.js'; 
import DualNBack from './DualNBack.js';
import Mirage from './Mirage.js';
import MLProjects from './MLProjects.js';
import StatArb from './StatArb.js'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dualnback" element={<DualNBack />} />
        <Route path="/mirage" element={<Mirage />} />
        <Route path="/ml-projects" element={<MLProjects />} />
        <Route path="/stat-arb" element={<StatArb />} />
      </Routes>
    </Router>
  </React.StrictMode>
);